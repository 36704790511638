.editor {
  line-height: 1.2;
}

.reactMarkDown {
  /* word-break: break-all;
    white-space: pre-wrap; */
  word-wrap: break-word;
}

.reactMarkDown h1 {
  font-size: 2rem;
  font-weight: 550;
  line-height: 1;
  color: var(--chat-bubble-onSurface);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

/* add standard styles for headings */
.reactMarkDown h2 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1;
  margin-top: 0.5em;
  color: var(--chat-bubble-onSurface);
  margin-bottom: 0.5em;
}

.reactMarkDown h3 {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1;
  margin-top: 0.5em;
  color: var(--chat-bubble-onSurface);
  margin-bottom: 0.5em;
}

.reactMarkDown h4 {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
  margin-top: 0.5em;
  color: var(--chat-bubble-onSurface);
  margin-bottom: 0.5em;
}

.reactMarkDown h5 {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  margin-top: 0.5em;
  color: var(--chat-bubble-onSurface);
  margin-bottom: 0.5em;
}

.reactMarkDown h6 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  margin-top: 0.5em;
  color: var(--chat-bubble-onSurface);
  margin-bottom: 0.5em;
}

/* add standard styles for paragraphs */

.reactMarkDown p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 0.5em;
  font-family: "Inter", serif;
  color: var(--chat-bubble-onSurface);
  margin-bottom: 0.5em;
}

/* add standard styles for lists */

.reactMarkDown ul {
  font-size: 1rem;
  font-weight: 350;
  line-height: 1.5;
  list-style: disc;
  margin-left: 16px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.reactMarkDown ol {
  font-size: 1rem;
  font-weight: 350;
  list-style: decimal;
  text-align: justify;
  margin-left: 22px;
  line-height: 1.5;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

/* add standard styles for links */

.reactMarkDown a {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: var(--color-link);
  text-decoration: none;
}

/* add standard styles for images */

.reactMarkDown img {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  max-width: 100%;
  height: auto;
}

/* add standard styles for code blocks */
.reactMarkDown pre {
  font-size: 0.8rem !important;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: black;
  background-color: var(--color-code-surface);
  border-radius: 0.5em;
  /* Padding around highlighted code block */
  padding: 15px;
  overflow-x: auto;
}

/* add standard styles for code block */

.reactMarkDown code {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  /* font-family: "Inconsolata", serif; */
  /* font-family: "Source Code Pro", serif; */
  font-family: "Fira Code", serif;
  color: var(--color-code-onSurface);
  border-radius: 0.5em;
  /* padding: 0.5em 0.5em; */
}

/* Styles for inline code blocks */
.reactMarkDown code:not(pre code) {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.2;
  background-color: var(--color-code-surface);
  color: var(--color-code-onSurface);
  border-radius: 0.3em;
  padding: 0.1em 0.3em;
  /* Add padding for inline code blocks */
}

/* add backtick styles for inline code */

/* .reactMarkDown code::before:not(pre code) {
    content: '"`"',
}

.reactMarkDown code::after:not(pre code) {
    content: '"`"',
}

*/
/* add standard styles for horizontal rules */

.reactMarkDown a {
  line-height: 100%;
}

.reactMarkDown p {
  line-height: 120%;
  font-weight: 350;
  text-align: justify;
}

.reactMarkDown hr {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: var(--chat-bubble-onSurface);
  background-color: var(--chat-bubble-onSurface);
  height: 1px;
}

/* add standard styles for blockquotes */

.reactMarkDown blockquote {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: var(--chat-bubble-onSurface);
  background-color: var(--color-block-surface);
  border-left: 0.25em solid var(--color-primary);
  padding: 0.5em;
}

/* add standard styles for tables */

.reactMarkDown table {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border: 1px solid var(--chat-bubble-onSurface);
  width: 100%;
  border-collapse: collapse;
}

.reactMarkDown th {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: var(--chat-bubble-onSurface);
  background-color: var(--color-background);
  border: 1px solid var(--chat-bubble-onSurface);
  padding: 0.5em;
}

.reactMarkDown td {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: var(--chat-bubble-onSurface);
  background-color: var(--color-background);
  border: 1px solid var(--chat-bubble-onSurface);
  padding: 0.5em;
}

/* standard css for bold */

.reactMarkDown strong {
  font-weight: 550;
  color: var(--chat-bubble-onSurface);
}

.fade {
  position: absolute;
  bottom: 0px;

  display: block;

  width: 100%;

  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9) 100%
  );
}
